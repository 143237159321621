import { Injectable } from '@angular/core';
import { AppService } from '../services/app.service';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import API from '@aws-amplify/api-rest';
import Storage from '@aws-amplify/storage';

declare const Buffer

@Injectable({
  providedIn: 'root'
})
export class AmplifyService {
  state = { people: [] };

  constructor(public app: AppService) { }

    currentUserToken() {
        return Auth.currentAuthenticatedUser().then((user) => {
            console.log(user.signInUserSession);
            return user.signInUserSession.idToken.jwtToken;
        });
    }

    checkUser() {
        Auth.currentAuthenticatedUser()
        .then(user => console.log({ user }))
        .catch(err => console.log(err));
    }

    signOut() {
        Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err));
    }

    async checkToken() {
        return new Promise((resolved, reject) => {
            Auth.currentAuthenticatedUser().then((user) => {
                console.log(user.signInUserSession);
                //return user.signInUserSession.idToken.jwtToken;

                this.app.sysData.cognitoTokens = user.signInUserSession;
                localStorage['sysData'] = JSON.stringify(this.app.sysData);
                //resolved(user.signInUserSession.idToken.jwtToken);
                resolved(true);
            }, err => {
                console.log('failed', err);
                reject(err);
            })
        });
    }


    storageGet(url) {
        return Auth.currentAuthenticatedUser().then((user) => {
            return new Promise((resolved, reject) => {
                console.log(user.username);
                if(!url) { url = user.username };
                
                Storage.list(url).then(result => {
                    console.log('listed directory', result);
                    resolved(result);
                }, err => {
                    console.log('listed directory failed', err);
                    reject(false);
                });
            });
        });

    }


    //UPLOAD FILE TO S3
    uploadFile(files, access) {
      console.log('file', files);
      var filename = files[0].name.replace(/ /g,"_");
      var ext = files[0].name.split('.').pop().toLowerCase();


      return Auth.currentAuthenticatedUser().then((user) => {
          return new Promise((resolved, reject) => {
              //var key = user.username + '/' + Date.now()  + '|' + filename;
              var key = this.app.sysData.company_id + '/' + Date.now()  + '|' + filename;
              Storage.put(key, files[0], { acl: access,
                  progressCallback(progress) {
                    console.log(`Uploaded percentage: ${progress.loaded}/${progress.total}`);

                  //   var status =  (progress.loaded/progress.total) * 100;
                  //   document.getElementById('progress-bar').setAttribute("style","width:" + status + "%");
                    //this.app.flags.uploadProgress = +progress.loaded/ +progress.total;
                  }}).then(result => {
                  console.log('upload successfull', result);
                  result['ext'] = ext;
                  result['filename'] = filename;
                  resolved(result);
              }, err => {
                  console.log('upload failed', err);
                  reject(false);
              });
          });
      });
    }

    //DELETE FILE FROM S3
    deleteFile(file) {
        return Auth.currentAuthenticatedUser().then((user) => {
            return new Promise((resolved, reject) => {
                file = file.replace(this.app.sysData.s3Root, ''); ///strip base URL so data url works

                Storage.remove(file)
                .then(result => {
                    console.log('deleting worked', result);
                    resolved(result);
                })
                .catch(error => {
                    console.error('error deleting', error);
                    reject(error);
                });
            });
        });
    }


    //APMLIFY FUNCTIONS
    authenticate(username, password) {
        return new Promise((resolved, reject) => {
            Auth.signIn({ username, password }).then(result => {
                console.log(result);
                resolved(result);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }

    resendConfirmationCode(username) {
        return new Promise((resolved, reject) => {
            Auth.resendSignUp(username).then(result => {
                console.log(result);
                resolved(result);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }

    currentAuthUser() {
        return new Promise((resolved, reject) => {
            Auth.currentAuthenticatedUser().then(data => {
                console.log(data);
                resolved(data);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }

    currentUser() {
        return new Promise((resolved, reject) => {
            Auth.currentUserInfo().then(data => {
                console.log(data);
                resolved(data);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }


    registerUser(username, password) {
        return new Promise((resolved, reject) => {
            Auth.signUp(username, password).then(data => {
                console.log(data);
                resolved(data);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }


    forgotPassword(username) {
        return new Promise((resolved, reject) => {
            Auth.forgotPassword(username).then(data => {
                console.log(data);
                resolved(data);
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }

    resetPassword(username, code, password) {
        return new Promise((resolved, reject) => {
            Auth.forgotPasswordSubmit(username, code, password).then(data => {
                console.log(data);
                resolved('success');
            }, err => {
                console.log('failed', err);
                reject(err)
            });
        });
    }






    //REST API CALLS
    get(url) {
        Auth.currentSession().then(session => {
            var jwtToken = session.getIdToken().getJwtToken();

            var options = { // OPTIONAL
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken //this.app.sysData.cognitoTokens.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            };

            console.log('url', url);
            API.get('V1-API', url, options).then(response => {
                // Add your code here
                console.log('data return', response);
                //return response.toPromise();

            })
            .catch(error => {
                console.log(error.response);
                //return error;
            });
        });
    }

    post(url, body) {
        Auth.currentSession().then(session => {
            var jwtToken = session.getIdToken().getJwtToken();

            var options = { // OPTIONAL
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken //this.app.sysData.cognitoTokens.idToken.jwtToken
                }, // OPTIONAL
                body: body, // replace this with attributes you need
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            };

            API.post('V1-API', url, options).then(response => {
                // Add your code here
                console.log('data return', response);
            })
            .catch(error => {
                console.log(error.response);
            });
        });
    }


    put(url, body) {
        Auth.currentSession().then(session => {
            var jwtToken = session.getIdToken().getJwtToken();

            var options = { // OPTIONAL
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken //this.app.sysData.cognitoTokens.idToken.jwtToken
                }, // OPTIONAL
                body: body, // replace this with attributes you need
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            };

            API.put('V1-API', url, options).then(response => {
                // Add your code here
                console.log('data return', response);
            })
            .catch(error => {
                console.log(error.response);
            });
        });
    }


    delete(url) {
        Auth.currentSession().then(session => {
            var jwtToken = session.getIdToken().getJwtToken();

            var options = { // OPTIONAL
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken //this.app.sysData.cognitoTokens.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            };

            API.del('V1-API', url, options).then(response => {
                // Add your code here
                console.log('data return', response);
            })
            .catch(error => {
                console.log(error.response);
            });
        });
    }
    //END API CALLS



}
