import { Injectable } from "@angular/core";
import { NavController, ToastController, AlertController, LoadingController } from "@ionic/angular";
import { Router } from '@angular/router';

import { Subject } from "rxjs";

//APPSYNC
import { SyncService } from "../services/sync.service";
import { Observable, ZenObservable } from "zen-observable-ts";

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';


declare var defaultSettings;
declare var document;

@Injectable({
  providedIn: "root",
})
export class AppService {
  //APPSYNC
  subscription: ZenObservable.Subscription;
  subscription2: ZenObservable.Subscription;

  private ticketFeed = new Subject<any>();

  sessions: any = [];

  sysData = defaultSettings;
  flags: any;

  twilioToken: any;
  device: any;
  call: any;

  constructor(
    public sync: SyncService,
    private navCtrl: NavController,
    public toastController: ToastController,
    public alertController: AlertController,
    public loadingCtrl: LoadingController,
    public router: Router
  ) {
    this.flags = {
      phoneOverlay: false,
      device: null,
      call: null,
      dialNumber: "",
      navigation: [],
      uploadProgress: 0,
      deviceConnection: null,
      appSync: { data: null, render: null },

      folders: [
        { id: 1, name: "Color & Objects", epoch: 1629683964000, type: "visual", data: null },
        { id: 2, name: "Shapes", epoch: 1629683964000, type: "visual", data: null },
      ],
      results: [
        {
          id: 1,
          folder_id: 1,
          resolution: "600",
          width: "800px",
          height: "600px",
          src: "./assets/img/games/test1.jpg",
          name: "Test1",
          directions: "Which one is NOT a green",
          text: { color: "#000000", size: "18px", position: "top" },
          interactions: [],
          epoch: 1629683964000,
          data: null,
        },
        {
          id: 2,
          folder_id: 1,
          resolution: "600",
          width: "800px",
          height: "600px",
          src: "./assets/img/games/test2.jpg",
          name: "Test 2",
          directions: "Which one is NOT a lady bug?",
          text: { color: "#000000", size: "18px", position: "top" },
          interactions: [
            {
              id: 1632269508066,
              show: true,
              text: "",
              color: "#000000",
              size: "18px",
              align: "center",
              type: "correct",
              width: 173,
              height: 459,
              x: "567",
              y: "35",
            },
          ],
          epoch: 1629687964000,
          data: null,
        },
        {
          id: 3,
          folder_id: 2,
          resolution: "600",
          width: "800px",
          height: "600px",
          src: "./assets/img/games/test3.jpg",
          name: "Test 3",
          directions: "Which one is NOT a circle",
          text: { color: "#000000", size: "18px", position: "top" },
          interactions: [],
          epoch: 1629681964000,
          data: null,
        },
      ],
    };

    if (localStorage["sysData"]) {
      //loda previous sysdata
      this.sysData = JSON.parse(localStorage["sysData"]);
    }

    this.call = { _status: "initialized" };
    this.setupNav();

    this.FCM();
  }

  public emitEvent(data: any) {
    console.log("event ran emitEvent");
    this.ticketFeed.next(data);
  }

  public get event$() {
    return this.ticketFeed.asObservable();
  }


  FCM() {
    console.log('Initializing HomePage');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        console.log('GOT PERMISSIONS ABA result: ' + JSON.stringify(result));
        PushNotifications.register();
      } else {
        // Show some error
        console.log('COULD NOT GET PERMISSION');
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        this.sysData.fcmToken = token.value;
        console.log('ABA Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('ABA Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('ABA Push received: ' + JSON.stringify(notification));

        let urlParts = this.router.url.split('/');
        let lastPart = '#'+urlParts.pop();

        if(notification.title != lastPart) {
          this.notify(notification.title + ': ' + notification.body, 'primary');
        }
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('ABA Push action performed: ' + JSON.stringify(notification));
        //this.alert(JSON.stringify(notification.notification.title));

        var str = notification.notification.title; // '#123444'
        if (str.startsWith('#')) {
          const target = str.slice(1); // '123444'
          console.log(target);
          this.navCtrl.navigateForward('/support-session/' + target);
        }

      }
    );
  
  }


  playSound() {
    console.log("PLAYING SOUND ##########");
    const audio = new Audio("assets/sound/alert-ticket.mp3");
    audio.play();
  }

  setupNav() {
    if (this.sysData.isLoggedIn) {
      //setup navigation
      var nav = [{ title: "Home", url: "/home", icon: "home" }];

      if (this.sysData.access == 1 || this.sysData.access == 3 || this.sysData.access == 9) {
        //if design only access
        nav.push({ title: "Sites", url: "/sites", icon: "location" });
        nav.push({ title: "Media", url: "/media", icon: "image" });
        nav.push({ title: "Devices", url: "/devices", icon: "layers" });
      }

      if (this.sysData.access == 2 || this.sysData.access == 3 || this.sysData.access == 9) {
        //if support only access
        nav.push({ title: "Support", url: "/support", icon: "ticket" });
      }

      if (this.sysData.access == 5 || this.sysData.access == 9) {
        //if reports only access
        nav.push({ title: "Reports", url: "/reports", icon: "pie-chart" });
      }

      if (this.sysData.access == 9) {
        //if users only access
        nav.push({ title: "Users", url: "/users", icon: "person" });
      }

      if (this.sysData.permission == 9) {
        //if admin add admin console
        nav.push({ title: "Admin Console", url: "/admin", icon: "cog" });
      }

      nav.push({ title: "Logout", url: "/login", icon: "log-out" }); //logout at end
      this.flags.navigation = JSON.parse(JSON.stringify(nav));

      //this.navCtrl.navigateRoot("/home");
    }
  }

  //START APP SYNC
  updateFeed() {
    //used to trigger a company wide update
    var syncTarget = "comp-" + this.sysData.company_id;

    this.sync
      .UpdateSync({
        id: syncTarget,
        status: status,
        data: "{}",
        responses: "null",
        commands: "companySessions",
        settings: "null",
      })
      .then((data) => {
        //console.log(data.data);
        console.log("Sync updated", data);
        //this.flags.appSync = data;
      });
  }

  subscribe() {
    //subscribed to company events
    console.log("starting subscription" + " | " + this.flags.deviceConnection);
    var syncTarget = "comp-" + this.sysData.company_id;

    this.subscription = this.sync
      .OnUpdateSyncListener(syncTarget)
      .map((syncObject) => syncObject.value.data["onUpdateSync"])
      .subscribe(
        (data) => {
          console.log("received update via app services app sync", data);
          var obj = JSON.parse(data["data"]);
          this.sessions = obj;
          this.emitEvent(obj);
        },
        (error) => {
          //this.app.alert('error' + error)
          console.log("logged a connection error", error);
          this.reSubscribe();
        }
      );
  }

  reSubscribe() {
    this.flags.online = false;
    console.log("subscription dead unsubscribing");
    this.unsubscribe();

    setTimeout(() => {
      console.log("retrying subscription");
      this.subscribe();
    }, 2000);
  }

  unsubscribe() {
    this.subscription.unsubscribe();
    this.flags.deviceConnection = null;
    console.log("unsubscribe");
  }

  updateSync() {
    //used to trigger a ticket update
    var epoch = Date.now();

    this.sync
      .UpdateSync({
        id: this.flags.deviceConnection.token,
        status: "",
        data: epoch.toString(),
        responses: "null",
        commands: "server_version",
        settings: JSON.stringify(this.flags.deviceConnection.settings),
      })
      .then((data) => {
        console.log("Sync updated", data);
        //this.flags.appSync = data;
      });
  }

  updateServerSync(m) {
    //this.flags.increment++;
    //var status = this.flags.increment.toString();
    var epoch = Date.now();
    var obj = JSON.parse(JSON.stringify(this.flags.deviceConnection));

    if (m == 1) {
      //PUSH VERSION UPTO SERVER
      this.sync
        .UpdateSync({
          id: obj.token,
          status: "",
          data: epoch.toString(),
          responses: "null",
          commands: "server_version",
          settings: "null",
        })
        .then((data) => {
          console.log("Sync updated", data);
          //this.flags.appSync = data;
        });
    }

    if (m == 2) {
      //SAVE SETTINGS UPTO SERVER
      obj.settings = JSON.stringify(obj.settings);

      this.sync
        .UpdateSync({
          id: obj.token,
          status: "",
          data: obj.settings,
          responses: "null",
          commands: "server_settings",
          settings: "null",
        })
        .then((data) => {
          console.log("Sync updated", data);
          //this.flags.appSync = data;
        });
    }
  }

  updateServerSync2() {
    //this.flags.increment++;
    //var status = this.flags.increment.toString();

    this.sync
      .UpdateSync({
        id: this.flags.deviceConnection.token,
        status: "",
        data: "",
        responses: "null",
        commands: "server_version",
        settings: JSON.stringify(this.flags.deviceConnection.settings),
      })
      .then((data) => {
        console.log("Sync updated", data);
        //this.flags.appSync = data;
      });
  }
  //END APP SYNC

  //START TWILIO VOICE FEATURES
  // togglePhone() {
  //   if(!this.flags.phoneOverlay) {
  //     this.flags.phoneOverlay = true;
  //   } else {
  //     this.flags.phoneOverlay = false;
  //   }
  // }

  // dialer(m) {
  //   if (m == 'dall') {
  //     this.flags.dialNumber = '';
  //   } else if (m == 'd') {
  //     this.flags.dialNumber = this.flags.dialNumber.slice(0, this.flags.dialNumber.length - 1);
  //   } else {
  //     this.flags.dialNumber = this.flags.dialNumber + m;
  //   }
  // }

  // incomingCalls() {
  //   //https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#methods //CALL METHODS
  //   //https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#methods //DEVICE METHODS
  //   // this.device.addListener('registered', device => {
  //   //   console.log('NEW DEIVCE RAADY FOR  calls.')
  //   // });
  //   //https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceoneventname-listener
  //   //https://www.twilio.com/blog/generate-access-token-twilio-chat-video-voice-using-twilio-functions //SERVER FUNCTIONS FOR TWILIO

  //   this.device.on('tokenExpired', token =>  {
  //     // Implement fetchToken() to make a secure request to your backend to retrieve a refreshed access token.
  //     // Use an authentication mechanism to prevent token exposure to 3rd parties.
  //     this.alert('EBBBBBB token expired needs refreshing ' + token);
  //     console.log("EBBBBBBBB  expiration token, ", token);

  //     //this.flags.twilioToken = this.flags.twilioToken.updateToken(this.flags.twilioToken);
  //   });

  //   this.device.on('registered', device => { //device is ready to receive calls
  //     console.log('The DEVICE REGISTERED FOR INCOMING CALLS.', device)
  //     this.flags.callFeature = 'online';
  //     this.flags.callState = 'ready';
  //   });

  //   this.device.on('incoming', call => { //call is incoming
  //     console.log('The NEW CALL COMING IN - INCOMING CALL.', call);
  //     this.call = call;
  //     this.flags.callState = 'incoming';
  //     this.flags.callFeature = 'incoming call';

  //     this.flags.dialNumber = this.call.parameters.From;
  //     this.flags.phoneOverlay = true;
  //   });

  //   this.device.on('accept', call => { //call is connected
  //     console.log('The incoming call was accepted or the outgoing calls media session has finished setting up.', call);
  //     this.flags.callState = 'connected';
  //     this.flags.callFeature = 'connected';
  //   });

  //   this.device.on('disconnect', call => { //call ended
  //     console.log('The call has been disconnected.', call);
  //     this.flags.callState = 'ready';
  //     this.flags.callFeature = 'waiting';
  //    });

  //    this.device.on('cancel', () => {
  //     console.log('The call has been canceled.');
  //     this.flags.callState = 'ready';
  //     this.flags.callFeature = 'cancelled';
  //    });

  //    this.device.on('error', (error) => {
  //     console.log('An error has occurred: ', error);
  //     this.alert('Error with call, ' + error);
  //     this.flags.callFeature = 'error ' + error;
  //   });

  //   this.device.on('reject', () => {
  //     console.log('The call was rejected.');
  //     this.flags.callState = 'ready';
  //     this.flags.callFeature = 'rejected';
  //   });

  //   this.device.register();
  // }

  // async outboundCall() {
  //   //https://www.twilio.com/docs/voice/sdks/javascript VOICE SDK

  //   var dial = '+1' + this.flags.dialNumber;

  //   //this.alert(dial);
  //   let call = await this.device.connect({
  //     params: {To: dial}
  //   }).then(call => {
  //     this.call = call;
  //     console.log(call);

  //     this.flags.callState = 'connected';
  //     this.flags.callFeature = 'open';
  //   });
  // }

  // hangupCall() {
  //   //this.device.disconnectAll();
  //   this.call.disconnect();
  //   console.log(this.call);
  //   this.flags.callState = 'ready';
  //   this.flags.callFeature = 'closed';
  // }

  // answerCall() {
  //   this.call.accept();
  //   this.flags.callState = 'connected';
  //   this.flags.callFeature = 'connected';
  // }

  // muteCall() {
  //   this.call.mute(true);
  //   console.log('MUTED', this.call);
  //   this.flags.callFeature = 'muted';

  // }

  // unmuteCall() {
  //   this.call.mute(false);
  //   console.log('UN-MUTED', this.call);
  //   this.flags.callFeature = 'unmuted';

  // }
  // //END TWILIO VOICE FEATURES

  alert(m) {
    this.presentAlert(m);
  }
  toast(m, c) {
    this.presentToast(m, c);
  }
  notify(m, c) {
    this.presentNotification(m, c);
  }

  load(m, d) {
    if (!d) {
      d == 0;
    }
    this.showLoading(m, d);
  }
  stopload() {
    this.loadingCtrl.dismiss();
  }

  async presentAlert(m) {
    const alert = await this.alertController.create({
      header: "Alert",
      subHeader: "Message",
      message: m,
      buttons: ["OK"],
    });

    await alert.present();
  }

  async presentToast(m, c) {
    const toast = await this.toastController.create({
      message: m,
      duration: 2000,
      color: c,
    });
    await toast.present();
  }

  async presentNotification(m, c) {
    const toast = await this.toastController.create({
      message: m,
      duration: 2300,
      color: c,
      position: "top",
    });

    await toast.present();
  }

  async showLoading(m, d) {
    const loading = await this.loadingCtrl.create({
      message: m,
      duration: d,
    });

    loading.present();
    await loading.onDidDismiss();
  }
}
