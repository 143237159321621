import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AppService } from '../services/app.service';
import { AmplifyService } from './amplify.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';



@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(public app: AppService, public amplify: AmplifyService, private httpW: HttpClient) { }


//IFRAME CHECKER
iframe(url) {
    return new Promise((resolved, reject) => {
        const headers: HttpHeaders = new HttpHeaders({
        'Content-Security-Policy': 'frame-ancestors *'
        });

        this.httpW.head(url, { headers })
        .subscribe(
            () => { 
                console.log("Iframe allowed") 
                resolved(true);
            },
            () => { 
                console.log("Iframe not allowed") 
                resolved(false);
            }
        );
    })
}
  

//GET
get(url) {
    var header = { 'Content-Type': 'application/json'};
    var option = { headers: new HttpHeaders(header) };

    console.log('get ' + url);
    return this.httpW.get(url, option).toPromise();
}

//POST
post(url, body) {
    console.log('post ' + url);
    return this.httpW.post(url, body, {}).toPromise();
}

//PUT
put(url, body) {
    console.log('post ' + url);
    return this.httpW.put(url, body, {}).toPromise();
}
//#####################################################################################################
//#####################################################################################################


//API GET
apiGet(url, params) {
    var target = this.app.sysData.apiTarget + url;
    console.log('apiGet ' + target + ' | params ' + params);

    return this.amplify.checkToken().then(status => {
        console.log('http token status', status);

        if (status == true) {
            var header = { 'Content-Type': 'application/json', 'Authorization': this.app.sysData.cognitoTokens.idToken.jwtToken };
            var option = { headers: new HttpHeaders(header) };
            return this.httpW.get(target, option).toPromise();
        } else {
            this.app.toast('Error 200R: Could not sync all data', 'danger');
        }
    }).catch((error) => { // Error
        this.app.toast('Error 201G: Could not sync all data', 'danger');
        console.log(error);
        return error;
    });
}



//API POST - new entry
apiPost(url, body, params) {
    var target = this.app.sysData.apiTarget + url;
    console.log('apiPost ' + target + ' | body ' + JSON.stringify(body) + ' | params ' + params);

    return this.amplify.checkToken().then(status => {
        console.log('http token status', status);

        if (status == true) {
            var header = { 'Content-Type': 'application/json', 'Authorization': this.app.sysData.cognitoTokens.idToken.jwtToken };
            var option = { headers: new HttpHeaders(header) };
            return this.httpW.post(target, body, option).toPromise();
        }
    }).catch((error) => { // Error
        this.app.toast('Error 201Po: Could not sync all data', 'danger');
        console.log(error);
        return error;
    });
}


//API PUT - update entry
apiPut(url, body, params) {
    var target = this.app.sysData.apiTarget + url;
    console.log('apiPut ' + target + ' | body ' + JSON.stringify(body) + ' | params ' + params);

    return this.amplify.checkToken().then(status => {
        console.log('http token status', status);

        if (status == true) {
            var header = { 'Content-Type': 'application/json', 'Authorization': this.app.sysData.cognitoTokens.idToken.jwtToken };
            var option = { headers: new HttpHeaders(header) };
            return this.httpW.put(target, body, option).toPromise();
        }
    }).catch((error) => { // Error
        this.app.toast('Error 201Pu: Could not sync all data', 'danger');
        console.log(error);
        return error;
    });
}


//API DELETE
apiDelete(url, body) {
    var target = this.app.sysData.apiTarget + url;
    console.log('apiDelete ' + target);

    return this.amplify.checkToken().then(status => {
        console.log('http token status', status);

        if (status == true) {
            var header = { 'Content-Type': 'application/json', 'Authorization': this.app.sysData.cognitoTokens.idToken.jwtToken };
            var option = { headers: new HttpHeaders(header) };
            return this.httpW.delete(target, option).toPromise();
        }
    }).catch((error) => { // Error
        this.app.toast('Error 201D: Could not sync all data', 'danger');
        console.log(error);
        return error;
    });
}
}
