// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  identityPoolId: "us-west-2:5e0f2b2c-98c9-434d-9784-44297b10fe03",
  cognitoClientId: "69q4vu2m1pn87o0a7q1kmemev2",
  cognitoPoolId: "us-west-2_uMMOmJwPh",
  bucketName: "arii-content",
  region: "us-west-2",

  graphqlAPI: {
    awsAppsyncGraphqlEndpoint: "https://xcd5om5kqbeb7ke2u3xkzyzpbu.appsync-api.us-west-2.amazonaws.com/graphql",
    awsAppsyncRegion: "us-west-2",
    awsAppsyncAuthenticationType: "AWS_IAM",
    awsAppsyncApiKey: "da2-eft336wemrbrfpxe6a4c7vcg2a",
  },
  amadeusAPI: {
    apiKey: "bmuPRusp030RXDGAzMojNYbTJijsTqP6",
    apiSecret: "AaCh0AG0qfkUH5Q9",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
