import { Injectable } from '@angular/core';
import { AppService } from '../services/app.service';
import { HttpService } from '../services/http.service';
import { interval, Subject } from 'rxjs';

//const Device = require('@twilio/voice-sdk').Device;
import { Device } from '@twilio/voice-sdk';


@Injectable({
  providedIn: 'root'
})
export class TwilioService {

  flags: any;
  client: any;
  
  device: any;
  line1: any;
  line2: any;
  call: any;


  constructor(public app: AppService, public http: HttpService) { 
    this.flags = {
      identity: 'Support',

      twilioToken: null,
      dialNumber: '',

      phoneOverlay: false,
      device: null,
      call: null,
      callState: 'Offline',
      muted: false,
      outboundID: null,
    };
  }

  


  getTwilioPhoneToken() {
    //https://www.twilio.com/docs/video/api/rooms-resource#create-room
    var room = 0;

    this.http.get('https://xmiiwtx0ab.execute-api.us-west-2.amazonaws.com/default/twilio/phone/').then(data => {
      console.log('GET PHONE TOKEN', data);
      
      //TWILIO VOICE INITIALIZATION
      this.line1 = new Device(data['token']);
      this.app.twilioToken = data['token'];
      this.phoneListeners();
    });



    // this.http.get('https://xmiiwtx0ab.execute-api.us-west-2.amazonaws.com/default/twilio/token/?room='+ room).then(data => {
    //   console.log('GET TWILIO TOKEN 2', data);
    //   //TWILIO VOICE INITIALIZATION
    //   this.line2 = new Device(data['token']);
    // });
  }


  refreshTwilioToken() {
    var identity = 'Support';
    var room = 0;
    this.http.get('https://xmiiwtx0ab.execute-api.us-west-2.amazonaws.com/default/twilio/token/?room='+ room).then(data => {

      console.log('GOT REFReSH TOKEN', data);
      this.app.twilioToken = data['token'];
      this.line1 = new Device(this.app.twilioToken);
    });
  }



  togglePhone() {
    if(!this.flags.phoneOverlay) {
      this.flags.phoneOverlay = true;
    } else {
      this.flags.phoneOverlay = false;
      this.flags.dialNumber = '';
    }
  }

  dialer(m) {
    if (m == 'dall') {
      this.flags.dialNumber = '';
    } else if (m == 'd') {
      this.flags.dialNumber = this.flags.dialNumber.slice(0, this.flags.dialNumber.length - 1);
    } else {
      this.flags.dialNumber = this.flags.dialNumber + m;
    }
  }


  phoneListeners() {
    //https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#methods //CALL METHODS
    //https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#methods //DEVICE METHODS
    // this.device.addListener('registered', device => {
    //   console.log('NEW DEIVCE RAADY FOR  calls.')
    // });
    //https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceoneventname-listener
    //https://www.twilio.com/blog/generate-access-token-twilio-chat-video-voice-using-twilio-functions //SERVER FUNCTIONS FOR TWILIO

    // this.device.on('tokenAboutToExpire', function() { //TOKEN EXPIRATION
    //   this.app.alert("PHEON TOKEN IS ABOUT TO GO UP IN SMOKE");
    //   // Implement fetchToken() to make a secure request to your backend to retrieve a refreshed access token.
    //   // Use an authentication mechanism to prevent token exposure to 3rd parties.
    //   // fetchToken(function(updatedToken) {
    //   //   chatClient.updateToken(updatedToken);
    //   // });
    // });

    this.line1.on('tokenWillExpire', function() { //TOKEN EXPIRATION
      console.log('TOKEN WILLL EXPIRE SCRIPT RAN');
      this.refreshTwilioToken();
      // Implement fetchToken() to make a secure request to your backend to retrieve a refreshed access token.
      // Use an authentication mechanism to prevent token exposure to 3rd parties.
      // fetchToken(function(updatedToken) {
      //   chatClient.updateToken(updatedToken);
      // });
    });

    this.line1.on('offline', function() { //TOKEN EXPIRATION
      this.app.alert('Phone system is offline');
      console.log('DEVCIE PHONE SyStem is OFFLINE');
    });



    this.line1.on('registered', device => { //device is ready to receive calls
      console.log('The DEVICE REGISTERED FOR INCOMING CALLS.', device)
    });
        



    this.line1.on('incoming', call => { //call is incoming
      console.log('The NEW CALL COMING IN - INCOMING CALL.', call);
      this.call = call;
      this.flags.callState = 'incoming';
      this.flags.phoneOverlay = true;

      this.callStateLister()
    });
    






    

    this.line1.on('accept', call => { //call is connected
      console.log('The incoming call was accepted or the outgoing calls media session has finished setting up.', call);
      this.flags.callState = 'connected';
    });

    this.line1.on('disconnect', call => { //call ended
      console.log('The call has been disconnected.', call);
      this.flags.callState = 'idle';
     });
     
     this.line1.on('cancel', () => {
      console.log('The call has been canceled.');
      this.flags.callState = 'idle';
     });

     this.line1.on('error', (error) => {
      console.log('An error has occurred: ', error);
      //this.app.alert('Error with call, ' + error);
    });
     
    this.line1.on('reject', () => {
      console.log('The call was rejected.');
      this.flags.callState = 'idle';
    });
     
    
    //this.device.register();
  }






  async outboundCall() {
    //https://www.twilio.com/docs/voice/sdks/javascript VOICE SDK

    this.flags.outboundID = Date.now();
    var dial = '+' + this.flags.dialNumber;

    //this.alert(dial);
    this.call = await this.line1.connect({ 
      params: {
        Outbound: true,
        From: '+18253052744',
        To: dial,
      } 
    });

    // .then(call => { 
    //   this.call = call; 
    //   console.log(call);
    //   //this.callStateLister()
    //   this.flags.callState = 'connected';
    // });
  }




  async inviteCaller() {
    //var dial = '+16504559111';
    this.call.transfer('+17807100695');

    var obj = this.call.activeConnection(); 
    console.log(obj);

    //this.call.transfer('+17807100695');

    // var dial = '+17807100695';
   
    // await this.line2.connect({ 
    //   params: {
    //     To: dial,
    //   } 
    // });

  }


  callStateLister() {
    console.log('The call listener registered.');

    this.call.on('cancel', () => {
      console.log('The call was Ended by either side, resetting state.');
      this.flags.callState = 'idle';
      this.flags.phoneOverlay = false;
    });
  }


  hangupCall() {
    this.call.disconnect();
    console.log(this.call);
    this.flags.callState = 'ready';
  }

  answerCall() {
    this.call.accept();
    this.flags.callState = 'connected';
  }

  muteCall() {
    this.call.mute(true);
    console.log('MUTED', this.call);
    this.flags.muted = true;

  }
  
  unmuteCall() {
    this.call.mute(false);
    console.log('UN-MUTED', this.call);
    this.flags.muted = false;
  }
  //END TWILIO VOICE
  
  
}
