import { Injectable } from '@angular/core';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { Observable } from 'zen-observable-ts';


export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}


export type SyncInput = { //data structure for sending update data sending data to graph ql
  id: string;
  status: string;
  data:String;
  responses: String;
  commands: string;
  settings: string;
};

export type UpdateSyncMutation = { //required for subscription response must match OnUpdateSyncSubscription
  __typename: 'Sync';
  id: string;
  status: string;
  data:String;
  responses: String;
  commands: string;
  settings: string;
};

export type GetMostRecentSyncQuery = { //on first load - single unit
  __typename: 'Sync';
  id: string;
  cid: string;
  data:String;
  responses: String;
  commands: String;
  settings: string;
};

export type GetMostRecentSyncsQuery = { //on first load multiple units
  __typename: 'Sync';
  id: string;
  cid: string;
  data:String;
  responses: String;
  settings: string;
};

export type OnUpdateSyncSubscription = {//required for subscription response must match UpdateSyncMutation
  __typename: 'Sync';
  id: string;
  status: string;
  data:String;
  responses: String;
  settings: string;
};



@Injectable({
  providedIn: 'root'
})
export class SyncService {

  constructor() { }

  async UpdateSync(sync: SyncInput): Promise<UpdateSyncMutation> {
    const statement = `mutation UpdateSync($sync: SyncInput!) {
    updateSync(sync: $sync) {
      __typename
      id
      status
      data
      responses
      commands
      settings
    }
  }`;
    const gqlAPIServiceArguments: any = {
        sync
    };
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <UpdateSyncMutation>response.data.updateSync;
  }


  async GetMostRecentSync(id: string): Promise<GetMostRecentSyncQuery> {
      const statement = `query GetMostRecentSync($id: String!) {
      getMostRecentSync(id: $id) {
        __typename
        id
        status
        data
        responses
        commands
        settings
      }
    }`;
      const gqlAPIServiceArguments: any = {
          id
      };
      const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
      return <GetMostRecentSyncQuery>response.data.getMostRecentSync;
  }


  async GetMostRecentSyncs(ids: Array<string>): Promise<Array<GetMostRecentSyncsQuery>> {
      const statement = `query GetMostRecentSyncs($ids: String!) {
      getMostRecentSyncs(ids: $ids) {
        __typename
        id
        status
        data
        responses
        commands
        settings
      }
    }`;
      const gqlAPIServiceArguments: any = {
          ids
      };
      const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
      return <Array<GetMostRecentSyncsQuery>>response.data.getMostRecentSyncs;
  }


  OnUpdateSyncListener(id: string): Observable<SubscriptionResponse<OnUpdateSyncSubscription>> {
      const statement = `subscription OnUpdateSync($id: ID!) {
        onUpdateSync(id: $id) {
          __typename
          id
          status
          data
          responses
          commands
          settings
      }
    }`;
      return API.graphql(graphqlOperation(statement, { id })) as Observable<SubscriptionResponse<OnUpdateSyncSubscription>>;
  }
  
}
