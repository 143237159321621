import { Component } from "@angular/core";
import { AppService } from "./services/app.service";
import { TwilioService } from "./services/twilio.service";
import { register } from "swiper/element/bundle";
import { MenuController } from "@ionic/angular";

register();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  public appPages = [
    // { title: 'Dashbaord', url: '/home', icon: 'grid' },
    { title: "Sessions", url: "/support", icon: "chatbox" },
    { title: "Experiences", url: "/sites", icon: "folder-open" },
    // { title: 'Directory', url: '/directory', icon: 'map' },
    // { title: 'Resources', url: '/resources', icon: 'book' },
    { title: "Access Points", url: "/devices", icon: "git-merge" },
    { title: "Media", url: "/media", icon: "images" },
    { title: "Reports", url: "/reports", icon: "pie-chart" },
    { title: "Users", url: "/users", icon: "people" },
    // { title: 'Experience', url: '/exp', icon: 'albums' },
    // { title: 'Admin Console', url: '/admin', icon: 'cog' },
    { title: "Logout", url: "/login", icon: "log-out" },
  ];
  constructor(public app: AppService, public twil: TwilioService, private menu: MenuController) {
    if (this.app.sysData.isLoggedIn) {
      //this.twil.getTwilioPhoneToken(); //get phone token
      console.log("SUBSCRIBING TO SYSTEM WIDE COMPANY EVENTS");
      this.app.subscribe(); //subscribe to feed events
    }
  }
  // toggleMenu(open: boolean) {
  //   if (open) {
  //     this.menu.open("first"); // 'first' is the ID of the ion-menu
  //   } else {
  //     this.menu.close("first");
  //   }
  // }
}
